section:nth-of-type(2) main{
    width: 100%;
    height: 70vh;
    position: relative;
}

.mySwiper img{
    max-width: 100%;
}

@media (max-width: 750px)
{
    section:nth-of-type(2) main{
        width: 70%;
        margin: 0 auto;
        height: 50vh;
    }
}