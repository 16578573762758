section {
    background-color: #50C3C9;
    padding: 65px;
    overflow: hidden;
}

section > .container{
    width: 80vw;
    max-width: 1500px;
    margin: 0 auto;
    position: relative;
}
@media (max-width: 750px)
{
    section {
        background-color: #50C3C9;
        padding: 20px;
    }

    section > .container{
        width: 90vw;
        display: block!important;
    }
}