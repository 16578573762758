header{
    position: fixed;
    overflow: hidden;
    width: 100vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #50c3c9;
    z-index: 1000;
}

nav{
    width: 100%;
    max-width: 1500px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

header nav div:first-of-type{
    display: flex;
    align-items: center;
    width: 50vw;
    position: relative;
    left: -3vw;
}

header nav div:first-of-type > img:first-of-type{
    height: 80%;
    margin-right: 5%
}

header nav div:first-of-type a img{
    height: 90%;
}

header nav div:first-of-type a{
    height: 100%;
}

header nav div:last-of-type a{
    display: flex;
    justify-content: flex-end;
    width: 50vw;
    margin-right: 10px;
}

header nav div:last-of-type button{
    background-color: #0B487F;
    border: none;
    height: 8vh;
    color: #fff
}

header nav div:last-of-type button:first-of-type{
    margin-right: 1vw;
    font-size: 40px;
    border-bottom-left-radius: 30px;
    padding: 0px 3%;
}
header nav div:last-of-type button:last-of-type{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
}

/* MOBILE */
@media (max-width: 750px)
{
    header{
        height: 10vh;
    }
    header nav div:first-of-type > img{
        height: 90%;
    }
    header nav div:first-of-type{
        left: -20vw;
    }
    header nav div:first-of-type a img{
        height: 100%;
    }
    header nav div:last-of-type button:first-of-type{
        font-size: 25px;
        height: 6vh;
    }
    header nav div:last-of-type button:last-of-type{
        font-size: 12px;
        height: 6vh;
    } 
}
@media (max-width: 450px)
{
    header nav div:first-of-type > img{
        display: none;
    }
    header nav div:first-of-type a img{
        height: 75%;
    }
    header nav div:first-of-type{
        left: 10px;
    }
}

/* ANIMAÇÕES */

.botaoApas{
    animation-duration: 1.5s;
    animation-name: slidedown;
}

.logoApas{
    animation-duration: 1.5s;
    animation-name: slideright;
}

@keyframes slidedown {
    from {
        margin-top: -100%;
    }
  
    to {
        margin-top: 0%;
    }
}

@keyframes slideright {
    from {
        margin-left: -100%;
    }
  
    to {
        margin-top: 0%;
    }
}