/* ESTILOS GERAIS */
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* PRIMEIRA SESSÃO */
section:first-of-type .container{
  padding-top: 15vh;
  display: flex;
}
section:first-of-type .container > img:first-of-type{
  position: absolute;
  right: 3%;
  top: 5%;
  z-index: 0;
}
section:first-of-type .container > img:last-of-type{
  position: absolute;
  right: -10%;
  top: -10%;
  z-index: 0;
  width: 15%;
}
section:first-of-type .container > div:first-of-type{
  position: relative;
  width: 40%;
  margin-right: 5%;
}
section:first-of-type .container > div:last-of-type{
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* SELO */
section:first-of-type .container > div:first-of-type img{
  width: 13%;
  position: absolute;
}
section:first-of-type .container > div:first-of-type img:first-of-type{
  top: 0;
  left: 0;
}
section:first-of-type .container > div:first-of-type img:nth-of-type(2){
  top: 0;
  right: 0;
}
section:first-of-type .container > div:first-of-type img:nth-of-type(3){
  bottom: 0;
  left: 0;
}
section:first-of-type .container > div:first-of-type img:last-of-type{
  bottom: 0;
  right: 0;
}
section:first-of-type .container > div:first-of-type div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
section:first-of-type .container > div:first-of-type div p > span{
  display: block;
  font-weight: 800;
}
section:first-of-type .container > div:first-of-type div p > span:first-of-type{
  color: #FCFDFC;
  font-size: 2.3em;
}
section:first-of-type .container > div:first-of-type div p > span:nth-of-type(2){
  line-height: 1;
}
section:first-of-type .container > div:first-of-type div p > span:nth-of-type(2),
section:first-of-type .container > div:first-of-type div p > span:nth-of-type(3)
{
  color: #0A477F;
  font-size: 1.9em;
}
section:first-of-type .container > div:first-of-type div p > span:last-of-type{
  color: #FCFDFC;
  font-size: 1.2em;
}

section:first-of-type .container > div:first-of-type div p > span:last-of-type span{
  color: #0A477F;
}

/* FIM DA PRIMEIRA SESSÃO */

/* SEGUNDA SESSÃO */
section:nth-of-type(2){
  background-color: #1C9796;
}
section:nth-of-type(2) .container > img:last-of-type{
  position: absolute;
  left: -10%;
  bottom: 0%;
  z-index: 0;
  width: 15%;
}
section:nth-of-type(2) .container > h2{
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.carrossel-xs{
  display: none!important;
}
.carrossel-lg{
  display: block;
}

/* FIM DA SEGUNDA SESSÃO */

/* TERCEIRA SESSÃO */
section:nth-of-type(3){
  background-color: #FFFFFF;
}
section:nth-of-type(3) .container > h2{
  color: #1C9796;
  text-align: left;
  margin-top: 0;
}
section:nth-of-type(3) .container > img:first-of-type{
  position: absolute;
  width: 20%;
  right: -20%;
  top: 50%;
}
section:nth-of-type(3) .container > img:last-of-type{
  position: absolute;
  left: -260px;
  width: 250px;
}
/* FIM DA TERCEIRA SESSÃO */

/* MOBILE */
@media (max-width: 750px)
{
  /* PRIMEIRA SESSÃO */
  section:first-of-type .container{
    padding-top: 10vh;
  }
  section:first-of-type .container > img:first-of-type{
    right: -10%;
    top: 46%;
    width: 45%;
    z-index: 0;;
  }
  section:first-of-type .container > img:last-of-type{
    display: none;
  }
  section:first-of-type .container > div:first-of-type{
    width: 100%;
    margin-bottom: 5vh;
  }
  section:first-of-type .container > div:last-of-type{
    width: 100%;
    position: relative;
  }
  section:first-of-type .container > div:first-of-type div p > span:first-of-type{
    font-size: 1.5em;
  }
  section:first-of-type .container > div:first-of-type div p > span:nth-of-type(2),
  section:first-of-type .container > div:first-of-type div p > span:nth-of-type(3)
  {
    font-size: 1.3em;
  }
  section:first-of-type .container > div:first-of-type div p > span:last-of-type{
    font-size: 1em;
  }
  /* FIM DA PRIMEIRA SESSÃO */
  /* SEGUNDA SESSÃO */
  .carrossel-lg{
    display: none!important;
  }
  .carrossel-xs{
    display: block!important;
  }
  /* FIM DA SEGUNDA SESSÃO */
  /* TERCEIRA SESSÃO */
  section:nth-of-type(3) .container > img:first-of-type{
    width: 20%;
    right: -10%;
    top: 90%;
  }
  section:nth-of-type(3) .container > img:last-of-type{
    display: none;
  }
  section:nth-of-type(3) .container > h2{
    text-align: center;
  }
  /* FIM DA TERCEIRA SESSÃO */
}

/* ANIMAÇÕES */
section:first-of-type .container > img:first-of-type{
  animation-duration: 15s;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}