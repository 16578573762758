footer {
    width: 77vw;
    min-height: 30vh;
    background-color: #0b487f;
    color: #fff;
    padding: 2vh 10vw 2vh 13vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

footer .container{
    width: 1500px;
    height: 100%;
    max-width: 100vw;
    display: flex;
}

footer .container > div{
    width: fit-content;
}

footer .container > div:first-of-type{
    height: 100px;
    margin: auto 0;
    padding: 0 5% 0 0;
}
footer .container > div:first-of-type > img{
    height: 100%;
}

footer .container > div:nth-of-type(3){
    padding: 0 5vw;
}
footer .container > div:nth-of-type(3) div:last-of-type{
    text-align: center;
}
footer .container > div:nth-of-type(3) img{
    width: 65%;
}

footer .container > div div{
    padding: 2vh 0;
}
footer span{
    display: block;
}
footer span:first-of-type{
    font-weight: 700;
    font-size: 1.11em;
}
footer span:last-of-type{
    font-size: 1.06em;
}

footer span a{
    color: white;
}

@media (max-width: 750px)
{
    footer {
        width: 90vw;
        min-height: 70vh;
        padding: 5vh 5vw;
        text-align: center;
    }
    footer .container{
        width: 90vw;
        display: block;
    }
    footer .container > div:first-of-type{
        display: none;
    }
    footer .container > div{
        width: 100%;
    }
    footer .container > div:nth-of-type(3){
        padding: 0;
    }
}