section:nth-of-type(3) main{
    min-height: 370px
}

section:nth-of-type(3) main div{
    display: flex;
}

.card{
    background-color: #1C9796;
    width: 30%;
    float: left;
    margin: 1%;
    height: 140px;
    border-radius: 15px;
    position: relative;
}

main .card:nth-child(2n){
    background-color: #35BFC2;
}

/* ICONE */

.card div:nth-of-type(1){
    position: absolute;
    bottom: -15px;
    left: 5%;
    color: #F7F078;
    width: 45%;
    height: 70px;
    background-color: #35BFC2;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px
}

main .card:nth-child(2n) div:nth-of-type(1){
    background-color: #1C9796;
}

/* NÚMERO */
.card div:nth-of-type(2){
    color: #ffffff;
    font-size: 45px;
    font-weight: 700;
    position: absolute;
    top: 15%;
    left: 5%;
}

/* TEXTO */
.card div:nth-of-type(3){
    color: #ffffff;
    font-weight: 700;
    position: absolute;
    bottom: 0%;
    right: 5%;
    width: 44%;
    font-size: 18px
}

.card:nth-of-type(1) div:nth-of-type(3){
    font-size: 35px
}
.card:nth-of-type(4) div:nth-of-type(3){
    font-size: 11px
}
/* MOBILE */
@media (max-width: 750px)
{
    section:nth-of-type(3) main{
        min-height: 550px
    }
    .card{
        width: 48%;
        margin-bottom: 30px;
    }

    .card div:nth-of-type(2){
        font-size: 35px;
        top: 25%;
    }

    .card div:nth-of-type(3){
        bottom: 5%;
        font-size: 10px
    }
    
    .card:nth-of-type(1) div:nth-of-type(3){
        font-size: 22px
    }
    .card:nth-of-type(4) div:nth-of-type(3){
        font-size: 8px
    }
}